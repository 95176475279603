/* global google,kafkaInfo */

import { daEventCenter } from 'public/src/services/eventCenter/index'
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'
import { asyncLoadFile } from '@shein/common-function'
import { googlePayMethods } from './config'
import { replacePaySuccessReferer } from '@/public/src/pages/checkout/components/tools.js'
import errorReport from '@/public/src/pages/common/handlePay/log'

daEventCenter.addSubscriber({ modulecode: '1-19-1' })

function makeForm(params, action, method) {
  $('form[name=\'pay_jump_form\']').remove()
  method = method || 'post'
  let $form = $(
    '<form name="pay_jump_form" action="' +
      action +
      '" method="' +
      method +
      '"></form'
  )
  for (const key in params) {
    let $input = $('<input type="hidden" />')
    $input.prop('name', key)
    $input.val(params[key])
    $form.append($input)
  }
  $form.append('<input type="submit" value="Continue" style="display:none;" />')
  $('body').append($form)
  document.pay_jump_form.submit()
}

const defaultCardNetworks = [
  'AMEX',
  'DISCOVER',
  'JCB',
  'MASTERCARD',
  'VISA'
]

const defaultAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

const useApolloConfig = gbCommonInfo?.PAY_CONFIG_SWITCH  == 1

// TODO:debug 调试信息，测试完删除
console.log('useApolloConfig', useApolloConfig)

export default class GooglePay {
  hasCreateBtn = false
  abtGoUnify = true // 是否走后端合并接口
  valid = -1 // 浏览器是否兼容谷歌pay: -1－还没校验，１－校验通过，－0校验不通过
  pay_fail_tips = {}

  merchantId = ''
  merchantName = ''
  gatewayMerchantId = ''
  routeId = '' // 前置路由接口返回的routeId，传入统一支付接口

  apiVersion

  apiVersionMinor

  paymentsClient = null

  language = {}

  orderInfo = {}
  // 多实例时，需要指定渲染的容器，如订单详情页发起一键购
  renderId = 'google-container'

  paymentMethods = []

  cardLogoMap = {}

  payConfig = null

  scene = '' // 场景 OrderDetail, OrderList, BuyPrime, GiftCard, GiftCardAgain, Checkout, OCP

  orderBuyType = '' // 目前仅订单列表使用，用于区分场景，public/src/pages/common/orderLogic/index.js#getBuyTpInfo

  constructor({ scene, renderId = '', orderBuyType = '' }) {
    this.scene = scene
    this.orderBuyType = orderBuyType
    if (renderId) {
      this.renderId = renderId
    }
    this._getLanguage()
    GooglePay.clearAllListener()
  }

  static googlePayMethods = gbCommonInfo?.GOOGLE_PAY_CODE_LIST || googlePayMethods

  _getLanguage() {
    schttp({
      url: '/api/common/language/get',
      params: { page: 'googlepay_fail_tips' }
    }).then(({ language }) => {
      this.pay_fail_tips = {
        w_7501: language.SHEIN_KEY_PWA_15467,
        w_7502: language.SHEIN_KEY_PWA_15468,
        w_7503: language.SHEIN_KEY_PWA_15469,
        w_7504: language.SHEIN_KEY_PWA_15470,
        w_7505: language.SHEIN_KEY_PWA_15471,
        w_7506: language.SHEIN_KEY_PWA_15472,
        w_7507: language.SHEIN_KEY_PWA_15473,
        w_7508: language.SHEIN_KEY_PWA_15474,
        w_7509: language.SHEIN_KEY_PWA_15475,
        w_7510: language.SHEIN_KEY_PWA_15476,
        w_7511: language.SHEIN_KEY_PWA_15477,
        w_7512: language.SHEIN_KEY_PWA_15478,
        w_7513: language.SHEIN_KEY_PWA_15479,
        w_301002: language['301002']
      }
    })
  }

  /**
   * 清除事件，防止在单页应用中触发多次监听
   *
   * @memberof GooglePay
   */
  static clearAllListener() {
    const googlePayEvents = [
      'isGooglePayLoading',
      'errorGooglePay',
      'showGooglePay',
      'cancelGooglePay'
    ]
    if (typeof appEventCenter === 'undefined') return
    googlePayEvents.forEach(evt => {
      appEventCenter.off(evt)
    })
  }

  _getGatewayName() {
    if (useApolloConfig && this.payConfig?.gateway) {
      return this.payConfig.gateway
    } else {
      switch (this.paymentCode) {
        case 'adyen-googlepay':
          return 'adyen'
        case 'nuvei-googlepay':
          return 'nuveidigital'
        case 'worldpay-googlepay':
          return 'worldpay'
        default:
          break
      }
    }
  }

  _getAllowedCardNetworks() {
    if (useApolloConfig) {
      let supportsCard = []
      const { cardLogoMap = {}, originPaymentCode, paymentMethods } = this
      const currentPayment = paymentMethods?.find?.(item => item?.code === originPaymentCode)
      if (currentPayment) {
        const { card_logo_list = [], apple_google_support_config_list = [] } = currentPayment
        card_logo_list.forEach(logo => {
          const cardInfo = apple_google_support_config_list?.find(item => item.card_type_logo === logo)
          const apolloCardInfo = cardLogoMap[logo]
          if (cardInfo && cardInfo.card_type) {
            supportsCard.push(cardInfo.card_type)
          } else if (!apple_google_support_config_list?.length && apolloCardInfo && apolloCardInfo.name) {
            supportsCard.push(apolloCardInfo.name)
          }
        })
      }
      if (supportsCard.length) {
        return supportsCard
      } else {
        return defaultCardNetworks
      }
    } else {
      return defaultCardNetworks
    }
  }

  _getCheckoutType() {
    const isGiftCard = ['GiftCard', 'GiftCardAgain'].includes(this.scene)
    const isBuyPrime = this.scene === 'BuyPrime' || this.orderBuyType === 1
    const isOCP = this.scene === 'OCP'

    let checkout_type = ''
    if (isGiftCard) checkout_type = 'giftcard'
    if (isBuyPrime) checkout_type = 'buyprime'
    if (
      ['Xtra', 'XtraDetail'].includes(this.scene) ||
      this.orderBuyType === 3
    ) checkout_type = 'xtra'
    if (isOCP) checkout_type = 'ocp'
    return checkout_type
  }

  _getProductType() {
    const checkoutType = this._getCheckoutType()
    switch (checkoutType) {
      case 'giftcard':
        return 'gift_card'
      case 'buyprime':
        return 'paid_membership'
      case 'xtra':
        return 'paid_shein_saver'
      case 'ocp':
        return 'one_click_pay'
      default:
        return 'normal'
    }
  }

  _sendErrorReport(
    payment_error_scene = '',
    params = {
      failure_type: '',
      client_url: '',
      status_code: '',
      description: ''
    }
  ) {
    errorReport.sendPaymentError(payment_error_scene, {
      failure_type: params?.failure_type || '',
      product_type: this._getProductType(),
      payment_action_type: 'google_pay',
      client_url: params?.client_url || '',
      error_data: {
        status_code: params?.status_code,
        uorder_id: this.orderInfo?.relation_billno,
        order_no: this.orderInfo?.billno,
        payment_method: this.paymentCode,
        description: params?.description || ''
      }
    })
  }

  // 验证是否支持google pay参数
  getGoogleIsReadyToPayRequest() {
    const apolloPayRequest = gbCommonInfo?.GOOGLE_READY_TO_PAY_REQUEST
    if (useApolloConfig && apolloPayRequest) {
      const { apiVersion = 2, apiVersionMinor = 0 } = apolloPayRequest
      this.apiVersion = apiVersion
      this.apiVersionMinor = apiVersionMinor
      return apolloPayRequest
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: defaultAuthMethods,
            allowedCardNetworks: defaultCardNetworks
          }
        }]
      }
    }
  }

  _isBr (order) {
    return order.countryCode === 'BR' || order.country_id === 30
  }

  getGooglePaymentDataRequest() {
    const _this = this

    let totalPrice = _this.orderInfo.totalPrice
    // 巴西政府税特殊传值逻辑 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1427084862
    if (_this._isBr(_this.orderInfo) && _this.paymentCode === 'adyen-googlepay' && _this.orderInfo.totalPriceWithGovTax) {
      totalPrice = _this.orderInfo.totalPriceWithGovTax
    }

    const merchantInfo = {
      merchantId: _this.merchantId, //测试环境可以不传，生产环境必须传该参数
      merchantName: _this.merchantName,
    }
    const transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: '' + totalPrice,
      countryCode: _this.orderInfo.countryCode,
      currencyCode:
        _this.orderInfo.currency_code || _this.orderInfo.currencyCode,
    }

    if (useApolloConfig) {
      const { apiVersion = 2, apiVersionMinor = 0, payConfig = {} } = _this
      const { allowedAuthMethods = defaultAuthMethods, type = 'CARD' } = payConfig || {}
      const allowedCardNetworks = _this._getAllowedCardNetworks()
      return {
        apiVersion,
        apiVersionMinor,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type,
            parameters: {
              allowedAuthMethods,
              allowedCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: _this._getGatewayName(),
                gatewayMerchantId: _this.gatewayMerchantId,
              },
            },
          }
        ],
        transactionInfo
      }
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: defaultAuthMethods,
              allowedCardNetworks: defaultCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: _this._getGatewayName(),
                gatewayMerchantId: _this.gatewayMerchantId,
              },
            },
          },
        ],
        transactionInfo
      }
    }
  }

  loadGooglePayFile() {
    if (window.google?.payments) return Promise.resolve()
    return new Promise(resolve => {
      asyncLoadFile({
        label: 'script',
        attrs: {
          src: 'https://pay.google.com/gp/p/js/pay.js'
        }
      }).then(() => {
        let loadGoogleInterval = setInterval(() => {
          if (window.google?.payments?.api) {
            resolve()
          }
          window.clearInterval(loadGoogleInterval)
        }, 1000)
      })
    })
  }

  getGooglePaymentsClient() {
    if (this.paymentsClient === null && typeof google !== 'undefined') {
      const environment = ['localhost', 'debug'].includes(
        gbCommonInfo.NODE_SERVER_ENV
      )
        ? 'TEST'
        : 'PRODUCTION'
      this.paymentsClient = new google.payments.api.PaymentsClient({
        environment: environment
      })
      // 虚拟支付：'TEST'，生产环境：'PRODUCTION'
    }
    return this.paymentsClient
  }

  async onGooglePayLoaded(info, cb) {
    const { createDom } = info
    const _this = this
    await this.loadGooglePayFile()
    this.hasCreateBtn = false
    var paymentsClient = this.getGooglePaymentsClient()

    if (paymentsClient) {
      paymentsClient
        .isReadyToPay(this.getGoogleIsReadyToPayRequest())
        .then(function (response) {
          // console.log(response)
          if (response.result) {
            _this.valid = 1
            createDom && _this.addGooglePayButton()
            cb && cb(true)
          } else {
            _this.valid = 0
            cb && cb(false)
          }
        })
        .catch(function (err) {
          // show error in developer console for debugging
          console.error(err)
          _this.valid = 0
          cb && cb(false)
          apiReport.report({
            apiPath: '/googlepay-err-ready',
            payment_code: _this.paymentCode,
            err: err && JSON.stringify(err)
          })
        })
    } else {
      _this.valid = 0
      _this._sendErrorReport('google_pay_sdk_error', {
        failure_type: 'sdk',
        client_url: '/third/sdk/error',
        description: 'googlePay paymentsClient找不到，调起sdk失败'
      })
      cb && cb(false)
    }
  }

  addGooglePayButton() {
    if (this.hasCreateBtn || typeof window == 'undefined') return
    var paymentsClient = this.getGooglePaymentsClient()
    var button = paymentsClient.createButton({
      onClick: this.onGooglePaymentButtonClicked.bind(this),
      buttonType: 'short'
    })
    document.getElementById(this.renderId).innerHTML = ''
    document.getElementById(this.renderId).appendChild(button)
    this.hasCreateBtn = true
  }

  // 点击去支付按钮，弹出谷歌弹窗
  onGooglePaymentButtonClicked() {
    const _this = this
    sa('send', {
      page_name: 'page_checkout_again',
      activity_name: 'click_google_pay_popup_goto',
      activity_param: ''
    })

    if (typeof appEventCenter !== 'undefined') {
      appEventCenter.emit('isGooglePayLoading', 1)
    }

    var paymentDataRequest = _this.getGooglePaymentDataRequest()
    //TODO: 调试信息,测试完删除
    console.log('paymentDataRequest：', paymentDataRequest)
    if (typeof appEventCenter !== 'undefined') {
      appEventCenter.emit('cancelGooglePay')
    }

    var paymentsClient = _this.getGooglePaymentsClient()

    if (!paymentsClient) {
      _this._sendErrorReport('google_pay_sdk_error', {
        failure_type: 'sdk',
        client_url: '/third/sdk/error',
        description: 'googlePay paymentsClient找不到，调起sdk失败'
      })
    }

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(function (paymentData) {
        // 验证成功
        _this.processPayment(paymentData)
      })
      .catch(function (err) {
        // alert(JSON.stringify(err))

        if (typeof appEventCenter !== 'undefined') {
          appEventCenter.emit('isGooglePayLoading', 0)
        }

        // alert('google错误：' + JSON.stringify(err));
        if (err && err.statusCode == 'CANCELED') {
          sa('send', {
            activity_name: 'click_google_pay_close',
            activity_param: ''
          })
          appEventCenter.emit('cancelGooglePay', { manualClose: true })
          _this._sendErrorReport('google_pay_pay_resultcode_cancel', {
            failure_type: 'web',
            client_url: '/third/sdk/error',
            description:
              'googlePay从google支付第三方sdk页面返回时statusCode为 CANCELED'
          })
        } else {
          console.error(err)
          if (typeof appEventCenter !== 'undefined') {
            appEventCenter.emit(
              'errorGooglePay',
              _this.pay_fail_tips['w_7513'],
              { is_guide: 1, billno: 'GSHMFG00T00005U', errorCode: 7559 }
            )
          }
          _this._sendErrorReport('google_pay_pay_resultcode_fail', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description:
              'googlePay从google支付第三方sdk页面返回时statusCode 为 ERROR'
          })

          // 除了用户主动取消外的异常上报
          apiReport.report({
            apiPath: '/googlepay-err',
            billno: _this.orderInfo.billno,
            payment_code: _this.paymentCode,
            err: err && JSON.stringify(err)
          })
        }
      })
    // });
  }

  processPayment(paymentData) {
    const _this = this
    // console.log('paymentData：', paymentData);
    const billno = _this.orderInfo.billno
    const res = paymentData
    let token = res?.paymentMethodData?.tokenizationData?.token
    const { host, langPath } = gbCommonInfo
    const SITE_URL = host + langPath
    let cbUrl = '/pay/result/unifiedCb'
    if (_this.paymentCode === 'nuvei-googlepay') {
      try {
        token = JSON.stringify(res?.paymentMethodData || {})
      } catch{
        return ''
      }
    }
    if (token) {
      const device_language =
        navigator.language || navigator.browserLanguage || ''

      const isGiftCard = ['GiftCard', 'GiftCardAgain'].includes(_this.scene)
      const isOCP = _this.scene === 'OCP'

      let checkout_type = _this._getCheckoutType()
      if (isOCP) {
        cbUrl = `/pay/result/ocpUnifiedCb?isOcp=1&ocpCbUrl=${encodeURIComponent(
          location.href
        )}&billno=${billno}`
      }

      //兼容chrome第三方跳转回来不带cookie
      let callbackUrl =
        SITE_URL +
        '/transform/middlePage?callbackFormUrl=' +
        encodeURIComponent(SITE_URL + cbUrl) +
        '&bill_no=' +
        billno +
        (checkout_type ? `&checkout_type=${checkout_type}` : '')

      let cancelUrl = callbackUrl
      let failureUrl = callbackUrl
      let pendingUrl = callbackUrl

      let formData = { billno: billno, token: token, device_language }
      const url = '/api/pay/unifiedPay/create'
      formData = {
        billno,
        callbackUrl,
        cancelUrl,
        failureUrl,
        pendingUrl,
        googlePayInfo: token,
        paymentCode: this.paymentCode,
        device_language,
        forterDeviceFingerprintID: window.forterDeviceId || '',
        forterSiteId: window.forterSiteId || '',
        riskifiedDeviceFingerprintID: window.isRiskifiedLoaded
          ? window.riskifiedDeviceId || ''
          : '',
        deviceFingerId: !isGiftCard ? window.GB_cybersource_uuid || '' : '',
        childBillnoList:
          _this.orderInfo.sub_billnos &&
          _this.orderInfo.sub_billnos?.map(v => v.sub_billno).join(','), // 子订单号
        checkout_type
      }
      // 同盾指纹
      if (window._fmOpt?.__blackbox) {
        formData.blackbox = window._fmOpt.__blackbox
      } else {
        // 未获取到同盾指纹上报
        apiReport.report({
          apiPath: 'tongdun/before-payment/error',
          sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
          billno: billno,
          paymentMethod: this.paymentCode
        })
      }
      if (window.forterSDKLoaded && !window.forterDeviceId) {
        //js初始化成功，且在发起支付时，前端给后端的指纹id为空
        apiReport.report({
          apiPath: 'forter/before-payment/error',
          siteuid: gbCommonInfo.SiteUID,
          scene: 'js sdk init success but forter deviceId is null',
          billno: billno,
          paymentMethod: this.paymentCode
        })
      }
      if (!isGiftCard && !window.GB_cybersource_uuid) {
        // 非礼品卡，发起支付时，前端给后端的指纹id为空
        apiReport.report({
          apiPath: 'cybs/before-payment/error',
          siteuid: gbCommonInfo.SiteUID,
          scene: 'cybs deviceId is null',
          billno: billno,
          paymentMethod: this.paymentCode
        })
      }

      if (['adyen-googlepay', 'nuvei-googlepay'].includes(_this.paymentCode)) {
        const DEVICE_INFO = {
          //acceptHeader: '',
          colorDepth: window.screen.colorDepth,
          javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
          language: window.navigator.language,
          screenHeight: window.screen.height || 0,
          screenWidth: window.screen.width || 0,
          timeZoneOffset: new Date().getTimezoneOffset(),
          userAgent: window.navigator.userAgent,
          origin: window.location.origin
        }

        Object.assign(formData, DEVICE_INFO)
        if (_this.paymentCode === 'nuvei-googlepay') {
          Object.assign(formData, { javaScriptEnabled: 1 })
        }
      }
      if (typeof appEventCenter !== 'undefined') {
        appEventCenter.emit('isGooglePayLoading', 1)
      }

      formData.neurPayId = billno + '_' + Math.floor(Math.random() * 99999)
      formData.neurStep = 1

      Object.assign(kafkaInfo, {
        neurStep: formData.neurStep,
        neurPayId: formData.neurPayId,
        startTime: +new Date(),
        callUrl: gbCommonInfo.host + url,
        billno: billno,
        paymentMethod: this.orderInfo.payment_method
      })

      // 前置路由接口，返回routeId传入统一支付接口
      if(this.routeId) {
        formData.routeId = this.routeId
      }

      if (!formData.googlePayInfo) {
        _this._sendErrorReport?.('google_pay_token_empty', {
          failure_type: 'api',
          client_url: '/pay/unifiedPay/create',
        })
      }

      schttp({
        url,
        method: 'POST',
        data: formData
      })
        .then(res => {
          kafkaInfo.sendData({ res, resStatus: 200, formData })

          if (res?.code != 0 || res?.info?.error_code) {
            _this._sendErrorReport?.('unified_pay_create_fail', {
              failure_type: 'api',
              client_url: '/pay/unifiedPay/create',
              status_code: res?.info?.error_code || res?.code,
              description: res?.info?.error_msg || res?.info?.show_error_msg
            })
          }

          const extraInfo = {
            is_guide: res.info && res.info.is_guide,
            billno: _this.orderInfo.billno,
            errorCode: res.info.error_code,
            res
          }

          let { action, paramList, actionUrl } = res.info

          if (res.code == 0 && res.info) {
            if (res.info.result == 1 && res.info.action == 'direct') {
              if (isOCP) {
                appEventCenter.emit?.('successGooglePay', {
                  res
                })
                return
              }
              if (!isGiftCard) {
                replacePaySuccessReferer()
              }
              window.location.href = `${SITE_URL}${
                isGiftCard ? '/giftcard' : ''
              }/pay/result/success?billno=${billno}`
            } else if (action == 'render' && paramList) {
              // form表单提交
              makeForm(paramList, actionUrl)

              if (typeof appEventCenter !== 'undefined') {
                appEventCenter.emit('isGooglePayLoading', 1)
              }
            } else if (res.info.result == 0 && res.info.error_code) {
              if (typeof appEventCenter !== 'undefined') {
                appEventCenter.emit(
                  'errorGooglePay',
                  res.info.show_error_msg ||
                    self.pay_fail_tips['w_' + res.info.error_code] ||
                    self.pay_fail_tips['w_7513'],
                  extraInfo
                )
                daEventCenter.triggerNotice({
                  daId: '1-19-1-22',
                  extraData: {
                    billno,
                    error_code: res.info.error_code,
                    isDetailPage: location.href.includes('/detail/')
                  }
                })
              }
            }
          } else {
            if (typeof appEventCenter !== 'undefined') {
              appEventCenter.emit('isGooglePayLoading', 0)
            }
            _this._sendErrorReport('google_pay_pay_center_error', {
              failure_type: 'api',
              client_url: url,
              status_code: res?.code,
              description: 'googlePay调用统一支付接口接口报错'
            })

            if (res.code == '301002') {
              if (typeof appEventCenter !== 'undefined') {
                appEventCenter.emit(
                  'errorGooglePay',
                  _this.pay_fail_tips['w_301002'],
                  { res }
                )
              }
            } else {
              if (typeof appEventCenter !== 'undefined') {
                appEventCenter.emit(
                  'errorGooglePay',
                  _this.pay_fail_tips['w_7513'],
                  { res }
                )
              }
            }
          }
        })
        .catch(err => {
          if (typeof appEventCenter !== 'undefined') {
            appEventCenter.emit(
              'errorGooglePay',
              _this.pay_fail_tips['w_7513'],
              { res }
            )
            appEventCenter.emit('isGooglePayLoading', 0)
          }
          _this._sendErrorReport('google_pay_pay_center_try_error', {
            failure_type: 'api',
            client_url: url,
            status_code: err?.status || '',
            description: `googlePay调用统一支付接口接口发生异常被捕获 ${
              err?.message || ''
            }`
          })

          console.error(err)
          let resStatus = err?.status || 0 //默认超时，传0
          kafkaInfo.sendData({ resStatus, formData })
        })
    }
  }

  isRelationBillno(billno = '') {
    if (!billno) return false
    return typeof billno?.startsWith === 'function' ? billno?.startsWith('U') : billno?.indexOf('U') === 0
  }

  getConfig({ code, cb } = {}) {
    var _this = this
    if (this.configLoading) return
    this.paymentCode = code
    this.originPaymentCode = code
    this.configLoading = true
    var billno = this.orderInfo.billno

    if(code === 'routepay-googlepay') {
      // 前置路由接口
      let params = {
        routeCode: this.orderInfo?.payment_method
      }

      if (_this.isRelationBillno(this.orderInfo?.billno)) {
        params.relationBillno = this.orderInfo?.billno
      } else {
        params.billno = this.orderInfo?.billno
      }
    
      if ('bin' in this.orderInfo) {
        params.bin = this.orderInfo?.bin
      }
    
      if ('tokenId' in this.orderInfo) {
        params.tokenId = this.orderInfo?.tokenId
      }
      schttpSync({
        url: '/api/checkout/payPreCardRouting/get',
        params: params,
        success: function (res) {
          _this.configLoading = false
          if (res.code == 0 && res.info) {
            // console.log('googlePayConfig：', res)
            _this.merchantId =
              res.info?.merchantInfo?.googleKey || ''
            _this.merchantName = res.info?.merchantInfo?.brand || ''
            _this.gatewayMerchantId = res.info?.merchantInfo?.googlePayMerchantId || ''
            _this.paymentCode = res.info?.payMethod
            _this.orderInfo.payment_method = res.info?.payMethod
            const { _apollo_pay_config, _apollo_google_pay_map } = res.info
            if (_apollo_pay_config && _apollo_pay_config[_this.paymentCode]) {
              _this.payConfig = _apollo_pay_config[_this.paymentCode]
            }
            if (_apollo_google_pay_map) {
              _this.cardLogoMap = _apollo_google_pay_map
            }
            _this.routeId = res.info?.routeId
            cb && cb()
          } else {
            // fix 订单已支付时的特殊处理
            // if (res?.code == '300317') {
            //   const { host, langPath } = gbCommonInfo
            //   const SITE_URL = host + langPath
            //   const checkoutType = _this._getCheckoutType()
            //   const isGiftCard = checkoutType === 'giftcard'
            //   if (!isGiftCard) {
            //     replacePaySuccessReferer()
            //   }
            //   window.location.href = `${SITE_URL}${isGiftCard ? '/giftcard' : ''}/pay/result/success?billno=${billno}`
            // }
            if (typeof (appEventCenter) !== 'undefined') {
              appEventCenter.emit(('errorGooglePay'), res?.tips || res?.msg || '', { billno, res })
            }
            _this._sendErrorReport('google_pay_config_error', {
              failure_type: 'api',
              client_url: '/api/checkout/payPreCardRouting/get',
              status_code: res?.code,
              description: `获取gp配置信息失败 ${res?.tips || res.msg || ''}`
            })
            try {
              sendFailApiReport(JSON.stringify(res?.info || ''))
            } catch {
              return ''
            }
          }
        },
        error: function (e) {
          _this.configLoading = false
          sendFailApiReport(e?.message || '')
          if (typeof appEventCenter !== 'undefined') {
            appEventCenter.emit('errorGooglePay', 'GOOGLE PAY ERROR')
          }
          _this._sendErrorReport('google_pay_config_error', {
            failure_type: 'api',
            client_url: '/api/checkout/payPreCardRouting/get',
            status_code: e?.status || null,
            description: `获取gp配置信息失败 ${e?.message || ''}`
          })
        }
      })
    } else {
      //这里为同步请求
      let url = '/api/checkout/newGooglepayConfig/get'
      if (code == 'worldpay-googlepay') {
        url = '/api/pay/worldpay/gp/config/get'
      }
      schttpSync({
        type: 'GET',
        url,
        params: { billno, paymentCode: code },
        success: function (res) {
          _this.configLoading = false
          if (res.code == 0 && res.info) {
            // console.log('googlePayConfig：', res)
            const { _apollo_pay_config, _apollo_google_pay_map } = res.info
            if (_apollo_pay_config && _apollo_pay_config[code]) {
              _this.payConfig = _apollo_pay_config[code]
            }
            if (_apollo_google_pay_map) {
              _this.cardLogoMap = _apollo_google_pay_map
            }
            _this.merchantId =
              res.info.googlepayMerchantId || res.info.merchantId || ''
            _this.merchantName = res.info.brand || ''
            _this.gatewayMerchantId =
              res.info.worldpayMerchantId || res.info.gatewayMerchantId || ''
            cb && cb()
          } else {
            // fix 订单已支付时的特殊处理
            // if (res?.code == '300317') {
            //   const { host, langPath } = gbCommonInfo
            //   const SITE_URL = host + langPath
            //   const checkoutType = _this._getCheckoutType()
            //   const isGiftCard = checkoutType === 'giftcard'
            //   if (!isGiftCard) {
            //     replacePaySuccessReferer()
            //   }
            //   window.location.href = `${SITE_URL}${isGiftCard ? '/giftcard' : ''}/pay/result/success?billno=${billno}`
            // }
            if (typeof (appEventCenter) !== 'undefined') {
              appEventCenter.emit(('errorGooglePay'), res?.tips || res?.msg || '', { billno, res })
            }
            _this._sendErrorReport('google_pay_config_error', {
              failure_type: 'api',
              client_url: url,
              status_code: res?.code,
              description: `获取gp配置信息失败 ${res?.tips || res.msg || ''}`
            })
            try {
              sendFailApiReport(JSON.stringify(res?.info || ''))
            } catch {
              return ''
            }
          }
        },
        error: function (e) {
          _this.configLoading = false
          sendFailApiReport(e?.message || '')
          if (typeof appEventCenter !== 'undefined') {
            appEventCenter.emit('errorGooglePay', 'GOOGLE PAY ERROR')
          }
          _this._sendErrorReport('google_pay_config_error', {
            failure_type: 'api',
            client_url: url,
            status_code: e?.status || null,
            description: `获取gp配置信息失败 ${e?.message || ''}`
          })
        }
      })
    }
  
    function sendFailApiReport(msg) {
      apiReport.report({
        apiPath: 'googlepay/fail_to_get_info',
        billno,
        payment_code: code,
        errInfo: msg
      })
    }
  }

  setOrderInfo(orderInfo) {
    this.orderInfo = Object.assign({}, orderInfo)
    console.log(this.orderInfo)
  }

  setPaymentMethods(paymentMethods) {
    this.paymentMethods = paymentMethods
  }
}
